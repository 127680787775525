import mainjson from './main';
import ChooseOverlay from '../components/msnosy/ChooseOverlay';
import React,{useEffect,useState,useRef}  from "react";
import {ExportExcel} from "../utils/HelperFunctions";
import axios from "axios";

async function openGenerate(subid){
    let ret = {component:<ChooseOverlay id={subid}></ChooseOverlay>};
    return ret;
}

async function exportCodes(subid){
    let body = {campaign:subid};
    let resultdata;
    await axios({
        method: "post",
        url: mainjson().get_codes_path,
        data: body,
    })
    .then((response) => {
    if (response.data) {
        resultdata = response.data;
    }
    })
    .catch((error) => {
        console.log("error "+error);
    });

    if (resultdata.data.url) {
        let fields = {
            titles:["Code","Insert Date","Used", "Allow multiple uses", "How many times used?"],
            fields:[{name:'campaign_code_code'},{name:'campaign_code_insert_date'},{name:'campaign_code_used',mapping:{1:"Yes",0:"No"}},{name:'campaign_code_allow_multiple_uses',mapping:{1:"Yes",0:"No"}}, {name:'campaign_code_times_used'}]
        };
        await ExportExcel("url",resultdata.data.url,fields,"codesexport.xls");
    }

}


export default function GetData() {
        return {
        "apitype":"codes",
        "id":"campaign_code_id",
        "subid":"campaign_code_id ",
        "subidname":"campaign_code_code",
        "options":["edit","delete"],
        "nameSingle":"code",
        "nameMultiple":"codes",
        "order":"campaign_code_insert_date",
        "extrabuttons":[
            {
                name:"Generate",
                action: openGenerate,
            },
            {
                name:"Export",
                action: exportCodes,
            }
        ],
        
        
        "fields":{
            "field1":{
                "name":"Code",
                "field":"campaign_code_code",
                "type":"Display",
                "list":true,
            },
            "field2":{
                "name":"Insert date",
                "field":"campaign_code_insert_date",
                "type":"DateTimeInput",
                "displayEdit":true,
                "list":true,
            },
            "field3":{
                "name":"Used",
                "field":"campaign_code_used",
                "type":"DropDown",
                "options": {
                    "0":"no",
                    "1":"yes",
                },
                "required":true,
                "list":true,
            },
            "field4":{
                "name":"Allow multiple uses",
                "field":"campaign_code_allow_multiple_uses",
                "type":"DropDown",
                "options": {
                    "0":"no",
                    "1":"yes",
                },
                "required":false,
                "list":true,
            },
            "field5":{
                "name":"How many times used?",
                "field":"campaign_code_times_used",
                "type":"TextInput",
                "required":false,
                "list":true,
            }
        }
    }
    
}